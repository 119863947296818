<template>
  <div class="container-fluid large-top-spacer">
    <div class="row">
      <div class="col-md-12">
        <h2>FAQ</h2>
        <hr class="hr-black">

        <h4>NOBB.IO</h4>
        <p class="underline">What is NOBB doing?</p>
        NOBB.io is a knowledge database platform, that enables everybody to easily communicate and share their ways and means for innovation processes amongst each other.
        It gives authors and companies the chance to publish their best-practices and principles and so
        NOBB.io gets a source for students, designers, managers, project leaders, strategists, product managers, ui/ux experts, marketeers, business developers, and so on.

        <p class="underline">What does ways-and-means stand for?</p>
        Ways and means is our acromion for best-practices, principles, concepts, strategies, tactics, methods, ... .
        All those ways and means have common targets:
        They improve our cooperation/team work/joint venture, make our product and service development smoother and make the driving forces behind our daily work transparent.

        <p class="underline">What does NOBB.io mean?</p>
        In January 2017 we started to discuss about a universal designing principle. What would be the best method to drive innovation processes? We had the same understanding that this is nothing a single author or company could come up with. So, we said “this is not a book, but ... something else”. What is something else? Our idea was a platform, where everybody could input his/her ways and means and all others could output their learnings.
        So we got “not a book but input/output”: NOBB.io, googled and translated nob and found the sugar beet.

        <p class="underline">What does NOBB.io mean?</p>

        <h4>Cards</h4>
        <p class="underline">What is NOBB doing?</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ'
}
</script>

<style scoped>

</style>
