<template>
  <!--d-none d-md-block-->
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-md-6 max-width-660">
        <title-component v-if="page.idCardPage" :idCardPage="page.idCardPage" :idCard="card.idCard"
                         v-bind:title.sync="card.title" :card="card" @updateCardTitle="updateCardTitle"
                         :disabled="!card.writePermissions || !card.editMode"
                         :placeholder="this.card.editMode ?  'Enter your title' : null"
                         class="hyphens"></title-component>

        <card-slideshow-component v-if="page.idCardPage" :idCardPage="page.idCardPage" :idCard="card.idCard"
                                  :card="card"></card-slideshow-component>
      </div>
    </div>


    <div class="row justify-content-center">
      <div class="col-md-6 max-width-660">
        <hr class="hr-card-divider"/>
        <div class="d-flex justify-content-between casualCardViewerControls" style="width: 100%;">
          <div>
            <a v-if="previousCard.available" v-on:click="loadCard(nextCard.newCard)">← Previous Card</a>
          </div>
          <div>
            <a v-if="nextCard.available" v-on:click="loadCard(nextCard.newCard)">→ Next Card</a>
          </div>
        </div>
        <hr class="hr-card-divider"/>

        <div class="cardCompany">
          <img :src="VUE_APP_NOBB_API + 'File/' + card.cardCompany.systemFileName" alt="CompanyLogo"
               v-if="card.cardCompany.systemFileName"/>
          <div class="company-round-box" :style="{'background-color': card.cardCompany.companyColor}"
               v-if="!card.cardCompany.systemFileName">{{ card.cardCompany.companyName.charAt(0) }}
          </div>
          {{ card.cardCompany.companyName }}

        </div>


        <subtitle-component v-if="page.idCardPage" :idCardPage="page.idCardPage" :idCard="card.idCard"
                            :disabled="!card.writePermissions || !card.editMode" :card="card"
                            :placeholder="this.card.editMode ? 'Enter description' : null"></subtitle-component>

        <div class="sources">
          <hr class="hr-black-bold"/>
          <TextComponent class="sources" min-height="50" v-if="page.idCardPage" :idCardPage="page.idCardPage"
                         :idCard="card.idCard" :disabled="!card.writePermissions || !card.editMode" :card="card"
                         idSection="3" character="100"
                         :placeholder="this.card.editMode ? 'Place your sources here!' : null "></TextComponent>
        </div>

      </div>
      <br>
    </div>
  </div>
</template>

<script>
import CardService from '../../services/CardService'
import CardSlideshowComponent from './sections/CardSlideshowComponent'
import SubtitleComponent from './sections/SubtitleComponent'
import TitleComponent from './sections/TitleComponent'
import EventBus from '../../event-bus.js'
import TextComponent from "./sections/TextComponent";

export default {
  name: 'FirstPage',
  props: ['card', 'previousCard', 'nextCard'],
  // eslint-disable-next-line vue/no-unused-components
  components: {TextComponent, TitleComponent, SubtitleComponent, CardSlideshowComponent},
  data() {
    return {
      page: {
        sections: [],
        pageNumber: 1,
        title: this.card.title,
        new: true
      },
      VUE_APP_NOBB_API: process.env.VUE_APP_NOBB_API,
    }
  },
  methods: {
    loadCard: function (card) {
      EventBus.$emit('loadCard', card)
    },
    getPage: function () {
      if (!this.card.pages || this.card.pages.length <= 0) {
        this.addPage()
      } else {
        for (let page of this.card.pages) {
          if (page.pageNumber === 1) {
            this.page.new = false

            CardService.getPageAndSections(this.card.idCard, page.idCardPage).then((response) => {
              this.page = response.data

              if (this.page.new) {
                this.addPage()
              }
            })
          }
        }
      }
    },
    addPage: function () {
      CardService.addPageToCard(this.card.idCard, this.page).then((response) => {
        this.page = response.data
        this.card.pages.push(this.page)
      })
    },
    updateCardTitle() {
      if (this.card.writePermissions) {
        CardService.updateCardInfo(this.card).then(() => {
          this.$emit('updateCard', this.card)
        })
      }
    }
  },
  mounted() {
    this.getPage()

    EventBus.$on('modeChange', mode => {
      this.$set(this.card, 'editMode', mode)
      this.$forceUpdate()
    })
  }
}
</script>

<style scoped>

</style>
