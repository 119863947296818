<template>
  <div id="app" version="0.2.2">
    <div class="fixed-top">
      <div class="float-left">
        <nav class="navbar navbar-expand-lg nobb-nav">
          <a class="navbar-brand" href="#/"><img src="./assets/branding/Rübe.png"/></a>
          <a href="#/"><h3 class="navbar-brand-text">NOBB.io</h3></a>
          <input id="search" type="text" class="form-control navbar-search" v-model="searchQuery"
                 v-on:input="searchCard" placeholder="Search for ways and means of innovation">
          <a href="#" style="display: none">Cancel</a>

          <div v-if="!userSession.loggedIn">
            <a href="#" style="margin: 0px 16px;" data-bs-toggle="modal" data-bs-target="#signUpModal"
               v-on:click="emitSignUpEvent" class="signUpLink">Sign Up </a>
            <button class="btn btn-login" data-bs-target="#signUpModal" data-bs-toggle="modal"
                    v-on:click="emitLoginEvent"><i class="loginText"> Log In </i>
              →
            </button>
          </div>
          <div v-if="userSession.loggedIn">
            <div class="dropdown">
              <button style="width: 100%" class="btn btn-secondary dropdown-toggle" type="button"
                      id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ userSession.company.companyName }}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#profileModal">Profile
                  settings ⦿</a></li>
                <li><a class="dropdown-item" href="#" v-on:click="logout">Logout &#8598;</a></li>
              </ul>
            </div>
          </div>
        </nav>

        <div style="position: absolute; top: 100px; width: 93px !important; height: 93px !important;">
          <img src="./assets/branding/testing-mode.svg" alt="We are in testing mode!">
        </div>

      </div>
    </div>
    <div class="app-container">
      <router-view/>
      <vue-progress-bar></vue-progress-bar>
    </div>

    <AlertComponent id="termsAndConditionsAlert" type="nobb-alert-primary" v-if="!acceptedTermsAndConditions"
                    message="HI THERE, THANK YOU SO MUCH FOR SUPPORTING US IN OUR FRIENDS-AND-FAMILY-TESTING. PLEASE REPORT ANY ISSUES TO HELLO@NOBB.IO. PLEASE BE AWARE THAT WE USE GOOGLE ANALYTICS AND HOTJAR FOR TRACKING."></AlertComponent>

    <footer>
      <div id="fixed-bottom">
        <div class="float-left">
          <div class="navbar navbar-expand-lg nobb-nav navbar-footer">
            <div class="d-flex justify-content-between" style="width: 100vw;">
              <div class="p-4">© NOBB.IO | Version 0.3.6 | {{ year }}</div>
              <div class="p-4"><a
                  href="mailto:info@nobb.io?subject=Question to NOBB.IO&body=Hi NOBB.IO, I have a question: ">Contact</a>
                | <a href="#/about">About NOBB.IO </a> | <a href="#/conditions">Terms and Conditions</a></div>

              <div class="p-4">
                <button class="back-to-top-btn" v-on:click="toTop">Back to top</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import EventBus from './event-bus'

import AlertComponent from "./components/alert/AlertComponent";

export default {
  name: 'App',
  components: {AlertComponent},
  data () {
    return {
      clickCount: 0,
      searchQuery: '',
      timer: 0
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
    acceptedTermsAndConditions() {
      const termsAndConditions = localStorage.getItem('termsAndConditions')

      return termsAndConditions === 'true'
    },
    userSession() {
      return this.$store.state.user
    }
  },
  mounted() {
    EventBus.$on("clearSearch", () => {
      this.searchQuery = ''
    })
  },
  methods: {
    searchCard() {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        EventBus.$emit('search-card', this.searchQuery)
      }, 850)
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    emitLoginEvent() {
      EventBus.$emit('login')
    },
    emitSignUpEvent() {
      EventBus.$emit('signup')
    },
    logout() {
      this.$store.commit('logoutUser')
    }
  }
}
</script>
