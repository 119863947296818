
<template>
    <div>
      <textarea-autosize class="textarea" :placeholder="this.placeholder"
                         :style="{color: card.textColor, 'min-height': this.minHeight + 'px'}"
                         v-model="section.value" ref="textarea" v-b-tooltip
                         :title="this.section.value.length + ' / ' + this.character"
                         :disabled="!card.writePermissions || !card.editMode"
                         :maxlength="this.character"
      />

      <AlertComponent v-on:close="error = {}" type="nobb-alert-warning" :message="error.message" v-if="error.message"></AlertComponent>
    </div>

</template>

<script>
import CardService from '../../../services/CardService'
import AlertComponent from '../../alert/AlertComponent'

export default {
  name: 'TextComponent',
  components: {AlertComponent},
  props: ['idCardPage', 'idCard', 'card', 'idSection', 'character', 'placeholder', 'minHeight'],
  data() {
    return {
      section: {
        idPageSection: '',
        value: '',
        position: 'TEXTSECTION' + this.idSection,
        type: 'DEFAULT'
      },
      error: {}
    }
  },
  methods: {
  },
  watch: {
    sectionValue: function () {
      if (this.section.value.length > this.character) {
        this.section.value = this.section.value.substr(0, this.character - 1)
      }

      let rowLength = this.section.value.split(/\r\n|\r|\n/).length

      if (rowLength > 12) {
        // alert('Surpassed maximum row length! ')

        this.error.message = 'Surpassed maximum row length! '
        this.section.value = this.section.value.substr(0, this.character - 5)
        this.section.value = this.section.value.trim()
      }

      // Create section since the user now wants to use it.
      if (this.section.idPageSection <= 0 && this.card.writePermissions) {
        this.section.idPageSection = 'blocked'
        CardService.addSectionToPage(this.idCard, this.idCardPage, this.section).then((response) => {
          this.section = response.data
        })
      } else {
        // Check if section is not still being added in the first request.
        if (this.section.idPageSection !== 'blocked' && this.card.writePermissions) {
          CardService.updateSection(this.idCard, this.idCardPage, this.section.idPageSection, this.section)
        }
      }
    }
  },
  mounted () {
    // Check if section already exists.
    CardService.getPageAndSections(this.idCard, this.idCardPage).then((response) => {
      if (response.data.sections) {
        for (let section of response.data.sections) {
          if (section.position === this.section.position) {
            this.section = section
          }
        }
      }
    })
  },
  computed: {
    sectionValue () {
      // Less weird watcher, please see watchers above.
      return this.section.value
    }
  }
}
</script>

<style scoped>
    .textarea {
        /*max-height: 500px;*/
        width: 100%;
        border: none;
        background: none;
        font-family: "Arial", serif;
        /*font-size: 4.5vw;*/
        color: black;
    }

</style>
