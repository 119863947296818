import Vue from 'vue'
import Router from 'vue-router'
import HomeComponent from '@/components/HomeComponent'
import TermsAndConditionsComponent from '@/components/about/TermsAndConditionsComponent'
import VueInputAutowidth from 'vue-input-autowidth'
import WelcomeEmailComponent from '../components/email/WelcomeEmailComponent'
import AboutComponent from '../components/about/AboutComponent'
import FAQComponent from '../components/about/FAQComponent'
import Hotjar from 'vue-hotjar'
import ResetPasswordComponent from "@/components/user/ResetPasswordComponent.vue";

Vue.use(Router)
Vue.use(VueInputAutowidth)
Vue.use(Hotjar, {
  id: '2391596'
})

export default new Router({
  routes: [
    {
      path: '/',
      name: 'HomeComponent',
      component: HomeComponent
    },
    {
      path: '/password/:hash',
      name: 'PasswordResetHomeComponent',
      component: ResetPasswordComponent,
      props: {
        collapseOpen: true
      }
    },
    {
      path: '/conditions',
      name: 'TermsAndConditionsComponent',
      component: TermsAndConditionsComponent
    },
    {
      path: '/welcome',
      name: 'WelcomeEmailComponent',
      component: WelcomeEmailComponent
    },
    {
      path: '/about',
      name: 'AboutComponent',
      component: AboutComponent
    },
    {
      path: '/faq',
      name: 'FAQComponent',
      component: FAQComponent
    },
    {
      path: '/dataprotection',
      name: 'DataProtectionComponent',
      component: HomeComponent,
      props: {
        dataProtection: true
      }
    },
    {
      path: '/activate/:activateHash',
      name: 'ActivateUserComponent',
      component: HomeComponent,
      props: {
        collapseOpen: true
      }
    },
    {
      path: '/invite/:userRegisterHash',
      name: 'UserRegisterComponent',
      component: HomeComponent,
      props: {
        collapseOpen: true
      }
    },
    {
      path: '/card/:idCard',
      name: 'CardLinkingComponent',
      component: HomeComponent,
      props: {}
    }
  ],
  scrollBehavior () {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    return {x: 0, y: 0}
  }
})
