<template>
  <div id="connectedCardGrid">
    <div class="card-deck" v-if="selectedCard.idCard == null">

      <div class="card connectedCard nohover" :style="{'background': card.cardColor, 'border': card.border}"
           :class="{'cardBorder': card.border}"
           v-for="card in cards"
           :key="card.idCard">

        <div class="card-body">
          <div class="margin-bottom">
            <div class="card-text text-uppercase special2" :style="{'color': card.textColor}">
              {{ card.title }}
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="col-md-12">
            <div class="card-text special">
              <div class="keep-bottom" :style="{'color': card.textColor}">
                {{ card.cardCompany.companyName }}
              </div>
            </div>
          </div>
        </div>
        <!-- Maximum of 2 cards width. A swipe gesture shall browse through the cards.                         -->

        <div class="link-card-info">
          <div class="link-card-metadata">
            <div class="link-card-metadata-item">
              <input type="radio" v-on:click="selectCard(card)" class=""/> Select

            </div>

            <div class="link-card-metadata-item">
              <a :href="'#/card/' + card.idCard" target="_blank">Preview in new tab</a> <br>

            </div>
          </div>
        </div>
      </div>

    </div>
    <div id="connectCard" v-if="selectedCard.idCard != null" class="connectCardPanel">
      <div class="connectCardPanel">

        <div class="card connectedCard nohover" :style="{'background': selectedCard.cardColor}"
             :class="{'cardBorder': selectedCard.border}"
             :key="selectedCard.idCard">

          <div class="card-body">
            <div class="margin-bottom">
              <div class="card-text text-uppercase special2" :style="{'color': selectedCard.textColor}">
                {{ selectedCard.title }}
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="col-md-12">
              <div class="card-text special">
                <div class="keep-bottom" :style="{'color': selectedCard.textColor}">
                  {{ selectedCard.cardCompany.companyName }}
                </div>
              </div>
            </div>
          </div>
          <div class="link-card-info">
            <div class="link-card-metadata">
              <div class="link-card-metadata-item">
                <a v-on:click.prevent="deselectCard(null)" style="cursor: pointer">X Deselect</a>

              </div>

              <div class="link-card-metadata-item">
                <a :href="'#/card/' + selectedCard.idCard" target="_blank">Preview in new tab</a> <br>
              </div>
            </div>
            <div class="link-card-description">
              <div class="form-group" style="width: 400px;">
                <label for="comment">Your Comment</label>
                <textarea id="comment" class="form-control" placeholder="Enter your comment on this card connection!"
                          v-model="selectedCard.comment"/>
                <button v-on:click="connectCard" class="btn btn-primary-nextgen mt-2 w-100"
                        :disabled="!selectedCard.comment">Connect Card ->
                </button>
                <button class="btn btn-primary-nextgen btn-close-white mt-2 w-100"
                        v-on:click="selectedCard.idCard = null">Cancel
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <alert-component type="nobb-alert-primary" :message="successAlert.message"
                     v-if="successAlert.show"></alert-component>
    <alert-component type="nobb-alert-warning" :message="errorAlert.message" v-if="errorAlert.show"></alert-component>

  </div>
</template>

<style scoped>

</style>

<script>

import CardService from "../../services/CardService";
import AlertComponent from "../alert/AlertComponent.vue";
import EventBus from "../../event-bus";

export default {
  name: 'CardSelectorComponent',
  components: {AlertComponent},
  props: ['userOnly', 'card'],
  data() {
    return {
      cards: [],
      successAlert: {
        show: false,
        message: ''
      },
      errorAlert: {
        show: false,
        message: ''
      },
      selectedCard: {
        idCard: null,
        title: '',
        cardCompany: {
          companyName: ''
        },
        comment: '',
        textColor: 'black'
      },
      selectedCardOriginal: {
        idCard: null,
        title: '',
        cardCompany: {
          companyName: ''
        },
        comment: ''
      }
    }
  },
  mounted() {
    this.loadAllCards()
  },
  methods: {
    loadAllCards() {
      CardService.getAllCards().then((response) => {
        this.cards = this.setCardColors(response.data)
      });
    },
    setCardColors: function (cards) {
      for (let i = 0; i < cards.length; i++) {
        cards[i].textColor = (parseInt(cards[i].cardColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff'
        if (cards[i].cardColor === '#ffffff') {
          cards[i].border = true
        }
      }

      return cards
    },
    selectCard(card) {
      console.log(this.selectedCard)

      this.selectedCard = card;
      this.$emit('select-card', card)
    },
    deselectCard() {
      this.selectedCard = this.selectedCardOriginal
    },
    connectCard() {
      CardService.connectCard(this.card.idCard, this.selectedCard.idCard, this.selectedCard.comment).then((response) => {
        EventBus.$emit('reloadCardConnections', this.card)
        this.deselectCard()

        this.successAlert.show = true
        this.successAlert.message = response.data.message
      }).catch((error) => {
        console.warn(error)
        this.successAlert.show = false
        this.errorAlert.show = true
        this.errorAlert.message = error.response.data.message
      })
    }
  }
}
</script>