<template>
  <div class="container-fluid large-top-spacer" id="#/about">
    <div class="row">
      <div class="col-md-12">
        <h3>TO OFFER YOU A PLATFORM FOR SHARING YOUR BRILLIANT WAYS AND MEANS FOR INNOVATION</h3>
        <h3>AND CONNECT TO LIKE-MINDED PEOPLE, WE – JOACHIM AND STEVEN – FOUNDED </h3>

        <h2>NOBB.IO</h2>
        <h2>THE WAYS & MEANS NETWORK</h2>
      </div>

      <div class="col-md-4">
        <h4 class="text-uppercase">
          Joachim is a {{ age }} years old engineer from Munich.
          His entire life he worked in advanced design and building up new business modelS,
          Such as Electric Vehicles, CarSharing and Energy Solutions.
          Joachim is especially interested in methods and processes on how to accelerate innovations
          and how to make relevant business out of them. <br>
          STEVEN IS A DESIGNER BASED IN BERLIN. HE LOVES CREATING FUTURES, WHICH
          MAKE PEOPLE FEEL GOOD, BE HAPPY AND THRIVE. TO DO SO, HE IS CURIOUS ABOUT EXPLORING
          NEW APPROACHES AND METHODS FOR DESIGN AND INNOVATION.
        </h4>

        <h4>
          Feel free to get in touch with Joachim & Steven <br>
          Via <a href="mailto:info@nobb.io" class="text-underline">info@nobb.io</a> <br>
          OR DOWNLOAD THE PITCH DECK <a href="../../../static/documents/NOBBio_PitchDeck_V16+.pdf"
                                        target="_blank">HERE</a>
        </h4>

        <br>
        <br>

        <h2>IMPRESSUM</h2>
        <h4>
          NOBB.IO <br>
          JOACHIM KOLLING | STEVEN BRÜNINGK LÜDERITZSTRASSE 54 13351 BERLIN <br>

          INFO@NOBB.IO
        </h4>
      </div>

      <div class="col-md-4 offset-2">
        <div class="about-image-holder" @mouseover="hover = true" @mouseleave="hover = false">
          <img class="img-fluid" src="../../assets/images/about-image-1.png" v-show="!hover">
          <a href="../../../static/documents/NOBBio_PitchDeck_V16+.pdf" target="_blank"><img class="img-fluid"
                                                                                             src="../../assets/images/about-image-2.png"
                                                                                             v-show="hover"></a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <h2 class="text-uppercase">Well what about...?! <br> Find answers for your questions around nobb.io:</h2>
        <hr class="hr-grey">
      </div>
    </div>
    <div class="row">
      <div class="col-md-10">
        <div class="accordion" id="faqAccordion">
          <div class="about-accordion">

            <div v-for="(item, index) in faq" :key="index">
              <div class="accordion-header" data-bs-toggle="collapse" aria-expanded="true" :aria-controls="'#collapse' + index + ',' + '#arrow' + index"
                   :data-bs-target="'#collapse' + index + ',' + '#arrow' + index">
                <h2 class="mb-0 no-italic">
                  {{ item.title }}
                  <img class="about-arrow" :id="'arrow' + index" data-bs-parent="#faqAccordion">
                </h2>
              </div>

              <div :id="'collapse' + index" class="collapse" aria-labelledby="headingOne" data-bs-parent="#faqAccordion"
                   :class="collapsed">
                <div class="accordion-card-body card-body">
                  <div class="col-md-6 no-padding"><h4 v-html="item.data"></h4></div>
                  <hr class="hr-grey">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../event-bus'

export default {
  name: 'AboutComponent',
  mounted() {
    EventBus.$on('search-card', resp => {
      this.collapsed = 'collapse show'
      window.find(resp)
      document.getElementById('search').focus().select()

    })
  },
  computed: {
    age() {
      var birthDay = new Date('January 9, 1965')

      var ageDifMs = Date.now() - birthDay.getTime()
      var ageDate = new Date(ageDifMs)

      return Math.abs(ageDate.getUTCFullYear() - 1970)
    }
  },
  data() {
    return {
      hover: false,
      collapsed: 'collapse',
      faq: [
        {
          title: 'How, What?',
          data: 'You can find a quick reference card here: <a href="static/documents/quick-reference-card_V1.pdf" class="login-href" target="_blank">NOBB Quick Reference Card </a>'
        },
        {
          title: 'What is NOBB.io doing?',
          data: 'NOBB.io is a knowledge database platform, that enables everybody to easily communicate and share their <u>ways and means</u> for innovation processes amongst each other.\n' +
              'It gives authors and companies the chance to publish their best-practices and principles and so\n' +
              'NOBB.io gets a source for students, designers, managers, project leaders, strategists, product managers, ui/ux experts, marketeers, business developers, and so on.   \n'
        },
        {
          title: 'What does ways-and-means stand for?',
          data: '<u>Ways and means</u> is our acromion for best-practices, principles, concepts, strategies, tactics, methods, ... .\n' +
              'All those ways and means have common targets:\n' +
              'They improve our cooperation/team work/joint venture, make our product and service development smoother and make the driving forces behind our daily work transparent.\n'
        },
        {
          title: 'What does NOBB.io mean?',
          data: 'In January 2017 we started to discuss about a universal designing principle. What would be the best method to drive innovation processes? We had the same understanding that this is nothing a single author or company could come up with. So, we said “this is not a book, but ... something else”. What is something else? Our idea was a platform, where everybody could input his/her ways and means and all others could output their learnings.\n' +
              'So we got “<u>no</u>t a <u>b</u>ook but <u>i</u>nput/<u>o</u>utput”: NOBB.io, googled and translated nob and found the sugar beet.\n'
        },
        {
          title: 'What is a card good for?',
          data: 'The card is the heart of NOBB.io. The author expresses his/her way and mean in a compact essence on the card.\n' +
              'The card has two sides. On the frontside we see a speaking title and a clear and catching image. On the backside an explanatory text gives the chance to detail the way and mean. More graphical types of visitors can flip through the images more text focused people can read through the text on the backside.\n'
        },
        {
          title: 'How do I create a card?',
          data: 'To create a card, you are asked to register/login. The reason is, that everybody knows who the author of a card is and to communicate to each other. When you press the card with the + sign, you open an editor which shows you the empty card. You now can easily enter texts and images and safe the card. Now the card is saved in your user area. The card is not yet published. Open the card and press the publish button. Now everyone else can see your newly created card.\n' +
              'If the content is not yours, please do not forget to add the list of references and the source!\n'
        },
        {
          title: 'How do I edit a card?',
          data: 'To edit a card you need to be logged in. In your user area you can select one of your created cards an edit it. On the card you see the edit symbol. Add, remove or edit texts or images. When you are happy with the result safe the card and if you wish publish the card, so that others can see your work. In case you are not happy and want to go back to the original version press undo.'
        },
        {
          title: 'How do I create tag words?',
          data: 'In the text fields you can create special tag words. They are used to create relations between the cards. So if two cards have the same tag word, they are connected to each other. You create a tag word simply by adding a hashtag in front of the word: #specialword'
        },
        {
          title: 'What is the difference between a private author and a company?',
          data: 'Every card published has one clearly identifiable author. The author can be a private person acting for him/herself. If a company wants to publish their <u>ways and means</u>, they first of all need an admin, who is responsible for the company’s cards. Other authors, which are employees of the company can then join the company by asking the admin, whether they are part of the company or not. After the admin approved that an author is part of the company, the author can publish cards in the name of the company.\n'
        },
        {
          title: 'Is it possible to join a company later on?',
          data: 'Yes, by informing us via <a href="mailto:info@nobb.io">info@nobb.io</a> you can ask for all kinds of editing.\n'
        },
        {
          title: 'What happens when my card is legally blocked?',
          data: 'In exceptional cases it can happen that a person or company claims the content of a card as their intellectual property. In this case NOBB.io blocks the card immediately and clarifies the issue. In this case the author of the card will be informed by email and asked to give a statement regarding the specific claim. '
        },
        {
          title: 'What happens when an author decides to leave a company?',
          data: 'Once an author decides to leave a company, the cards that are published by the author under the name of the company can be transferred to any other author that is part of the company. To do so, simply reach out to nobb.io via info@nobb.io and we will transfer the cards to a new author. Make sure to name both authors in your email – the resigning author and the author that shall take over the cards. Once both authors agree on the transfer it can be executed by nobb.io.'
        },
        {
          title: 'Where are my cards saved?',
          data: 'Your cards are saved on a cloud server in Europe'
        }
      ]
    }
  }
}
</script>

<style scoped>

.anchor {
  position: relative;
}

.anchor a {
  position: absolute;
  left: 0px;
  top: -90%;
}

a {
  color: #212529;
}

a:hover {
  color: #707070;
}

p {
  font-family: Arial, serif;
  font-weight: 100;
  letter-spacing: -1px;
}

ul {
  list-style: none;
  font-style: italic;
  font-size: 14pt;
  letter-spacing: -1.5px;
  padding-left: 3px;
}

li {
  margin: 0 0 -7px 0;
}

h3 {
  letter-spacing: -1px;
}

h2 {
  font-size: 24pt;
  letter-spacing: -0.5px;
  word-spacing: -0.5px;
}

h5 {
  font-size: 14pt;
  font-style: italic;
  letter-spacing: -1px;
}

h4 {
  font-size: 13pt;
  font-style: italic;
  letter-spacing: -1px;
}

.no-italic {
  font-style: normal !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.no-padding {
  padding: 0 !important;
}

</style>
