
<template>
    <div class="nobb-alert-2">
      <div class="clipped" v-bind:class="[type]" v-touch:swipe.left="swiped" v-show="!hide">
        <p>{{message}} <br> <a href="#" class="underline" v-on:click="acknowledgeRequest">{{ackMessage}}</a> <a href="#" class="underline" v-on:click="rejectRequest($event)">{{rejectMessage}}</a></p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'DecisionAlertComponent',
  props: ['type', 'message', 'ackMessage', 'rejectMessage'],
  data () {
    return {
      alert: true,
      hide: false
    }
  },
  methods: {
    swiped: function () {
      this.hide = 'close-alert'
    },
    close: function () {
      this.hide = 'close-alert'
      this.$emit('close')
    },
    acknowledgeRequest: function () {
      this.$emit('acknowledged')
    },
    rejectRequest: function () {
      this.$emit('rejected')
    }
  }
}
</script>
