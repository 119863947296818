import axios from 'axios'
import app from '../main'
import {store} from '../store'

export default() => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_NOBB_API,
    withCredentials: false,
    headers: {
      'Authorization': 'Bearer ' + store.getters.getJwtToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(config => {
    app.$Progress.start()

    return config
  })

  instance.interceptors.response.use(response => {
    app.$Progress.finish()

    return response
  }, (error) => {
    app.$Progress.fail()

    return Promise.reject(error)
  })

  return instance
}
