<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 medium-top-spacer">
        <div class="row">
          <h3 class="text-uppercase">Please take your time to read our</h3>
          <h2 class="text-uppercase">Terms of use & <i class="underline">privacy policy</i></h2>
        </div>
        <div class="row">
          <ul>
            Deutsch
            <li class="text-uppercase"><a href="#allgemeines">01 Allgemeines</a></li>
            <li class="text-uppercase"><a href="#datenschutz">02. Datenschutz</a></li>
            <li class="text-uppercase"><a href="#quellenangaben">03. Quellenangaben</a></li>
            <li class="text-uppercase"><a href="#unserrecht"> 04. Unser Recht</a></li>
            <li class="text-uppercase"><a href="#fragen">05. Bei Fragen</a></li>
            <br>
            English
            <li class="text-uppercase"><a href="#in_a_nutshell">01. In a nutshell</a></li>
            <li class="text-uppercase"><a href="#dataprotection">02. Data protection</a></li>
            <li class="text-uppercase"><a href="#references">03. References</a></li>
            <li class="text-uppercase"><a href="#ourrights">04. Our rights</a></li>
            <li class="text-uppercase"><a href="#questions">05. Questions</a></li>
          </ul>
        </div>

      </div>

      <div class="col-md-5 large-top-spacer">
        <div class="row anchor">
          <a name="allgemeines"></a>
          <h5><u>Allgemeines</u></h5>
          <h5>Hallo zusammen,
            wir betreiben diese Webpage aktuell als Non-Profit Pilot.
            Unser Ziel ist es Wissen über unterschiedliche Methoden, Prozesse (ways&means) zu sammeln und öffentlich
            zugänglich zu machen.
            Dazu habt Ihr (als Autoren) die Möglichkeit bekanntes Wissen (mit Quellenangabe) aber auch neue Erkenntnisse
            (nach eigenem Dafürhalten, ohne Quellenangabe) in unserem Layout (Karten) zu veröffentlichen und in unserer
            Datenbank abzuspeichern.
            Um den Erhalt der Seite mittelfristig zu finanzieren planen wir Firmen, die Möglichkeit zu geben ihre Karten
            mit einem link zu Ihrer eigenen Firmenpräsenz zu versehen und hierfür eine Gebühr zu verlangen.
            <br>
            <br>
            Auf folgende Punkte möchten wir hinweisen:
          </h5>
        </div>

        <div class="row anchor">
          <a name="datenschutz"></a>
          <h5>
            <u>Datenschutz</u>
            <ul class="bullet-list">
              <li>Wir erheben Daten, um die Karten untereinander vernetzen zu können.</li>
              <li>Personenbezogene Daten werden auf dieser Webseite nur im technisch notwendigen Umfang erhoben. In
                keinem Fall werden die erhobenen Daten verkauft oder aus anderen Gründen an Dritte weitergegeben.
              </li>
              <li>Log-Files: Bei der Nutzung dieses Angebots werden die von Ihrem Browser an den Server übermittelten
                Daten erfasst und gespeichert. Dies umfasst üblicherweise Informationen über den Typ und die Version des
                von Ihnen verwendeten Browsers, das verwendete Betriebssystem, die Referrer URL (die Webseite, von der
                aus Sie zu dieser Website gelangt sind, den Hostnamen des zugreifenden Rechners (die IP Adresse) sowie
                die Uhrzeit der Serveranfrage. In der Regel lassen sich diese Daten nicht bestimmten Personen - und
                damit auch nicht Ihnen - zuordnen. Weder wird zu diesem Zweck ein Abgleich der Daten mit anderen Daten
                vorgenommen, noch werden sie hierfür mit anderen Daten zusammengeführt. Die Daten werden regelmäßig nach
                einer statistischen Auswertung gelöscht.
              </li>
              <li>Kartendaten: Wir erfassen wann eine Karte geöffnet wird, in welcher Reihenfolge die Karten angesehen
                werden und wie lange die einzelne Karte geöffnet bleibt. Diese Daten sind den Karten zugeordnet, nicht
                den Personen.
              </li>
            </ul>
          </h5>
        </div>

        <div class="row anchor">
          <a name="quellenangaben"></a>
          <h5>
            <u>Quellenangaben</u></h5>
          <h5>
            Es ist uns außerordentlich wichtig, dass das hier gezeigte Wissen mit sauberen Quellenabgaben versehen ist.
            Hierzu hat jede Karte ein Quellentextfeld.
            Ihr seid verpflichtet genau zu überlegen, ob das Knowhow auf Eurer Karte von Euch selber stammt oder von wem
            genau Ihr es habt. Nur im Ausnahmefall sollte hier „weiß nicht“ angegeben werden.
            Jeder Autor ist für den Inhalt und die Urheberrechte seiner Karten selbst verantwortlich!
            Jeder Autor garantiert uns, dass er die Urheberschaft an seinen Inhalten hat oder sauber die Quellen
            angegeben hat.
          </h5>
        </div>

        <div class="row anchor">
          <a name="unserrecht"></a>
          <h5>
            <u>Unser Recht</u></h5>
          <h5>
            Wir behalten uns vor, jederzeit ohne Angabe von Gründen die Karten offline zu nehmen.
            Dies gilt vor Allem, wenn die Karten missbräuchlich für politische Inhalte, Hetze oder Diskriminierung
            genutzt werden.
          </h5>
        </div>

        <div class="row anchor">
          <a name="fragen"></a>
          <h5><u>Bei Fragen</u></h5><br>

        </div>
        <h5>
          <span>Bitte kontaktiert uns unter <a href="mailto:info@nobb.io">info@nobb.io</a></span>
        </h5>

        <hr>

        <div class="row anchor">
          <a name="in_a_nutshell"></a>
          <h5>
            <u>In a nutshell</u></h5>
          <h5>
            Hello, everybody,
            we are currently running this webpage as a non-profit pilot.
            Our goal is to collect knowledge about different methods, processes (ways&means) and to make them publicly accessible.
            In addition, you (as authors) have the possibility to publish known knowledge (with source indication) but also new findings (according to your own opinion, without source indication) in our layout (cards) and save them in our database.
            In order to finance the maintenance of the site in the medium term, we are planning to give companies the opportunity to link their cards to their own corporate presence and charge a fee for this.

            We would like to point out the following points:
          </h5>
        </div>

        <div class="row anchor">
          <a name="dataprotection"></a>
          <h5>
            <u>Data protection</u></h5>
          <h5>
            <ul class="bullet-list">
              <li>We collect data in order to be able to network the cards with each other.</li>
              <li>Personal data is only collected on this website to the extent that it is technically necessary. Under no circumstances will the collected data be sold or passed on to third parties for any other reason.</li>
              <li>Log files: When using this service, the data transmitted from your browser to the server is recorded and stored. This usually includes information about the type and version of the browser you are using, the operating system used, the referrer URL (the website from which you accessed this website, the host name of the accessing computer (the IP address) and the time of the server request. As a rule, this data cannot be assigned to specific persons - and therefore not to you. Neither will the data be compared with other data for this purpose, nor will it be merged with other data for this purpose. The data are regularly deleted after a statistical evaluation</li>
              <li>Card data: We record when a card is opened, in which order the cards are viewed and how long the individual card remains open. These data are assigned to the cards, not to the persons.</li>
            </ul>
          </h5>
        </div>

        <div class="row anchor">
          <a name="references"></a>
          <h5>
            <u>References</u></h5>
          <h5>
            It is extremely important to us that the knowledge shown here is accompanied by clean source data.
            Each card has a source text field for this purpose.
            You are obliged to consider carefully whether the know-how on your card comes from yourself or from whom exactly you have it. Only in exceptional cases "do not know" should be indicated here.
            Every author is responsible for the content and copyrights of his cards!
            Each author guarantees us that he has the authorship of his contents or has indicated cleanly the sources.
          </h5>
        </div>

        <div class="row anchor">
          <a name="ourright"></a>
          <h5>
            <u>Our Right</u></h5>
          <h5>
            We reserve the right to take the cards offline at any time without giving reasons.
            This applies in particular if the cards are misused for political content, agitation or discrimination.
          </h5>
        </div>

        <div class="row anchor">
          <a name="questions"></a>
          <h5>
            <u>Questions </u></h5>
        </div>

        <div class="row">
          <h5>If you have any, please contact <a href="mailto:info@nobb.io">info@nobb.io</a> </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../event-bus'

export default {
  name: 'TermsAndConditionsComponent',
  mounted () {
    EventBus.$on('search-card', resp => {
      window.find(resp)

      document.getElementById('search').focus().select()
    })
  },
}
</script>

<style scoped>

  .anchor {
    position: relative;
  }

  .anchor a {
    position: absolute;
    left: 0px;
    top: -90%;
  }

  a {
    color: #212529;
  }

  a:hover {
    color: #707070;
  }

  p {
    font-family: Arial, serif;
    font-weight: 100;
    letter-spacing: -1px;
  }

  ul {
    list-style: none;
    font-style: italic;
    font-size: 14pt;
    letter-spacing: -1.5px;
    padding-left: 3px;
  }

  li {
    margin: 0 0 -7px 0;
  }

  h3 {
    letter-spacing: -1px;
  }

  h2 {
    letter-spacing: -0.5px;
  }

  h5 {
    font-size: 14pt;
    font-style: italic;
    letter-spacing: -1px;
  }

  h4 {
    font-size: 13pt;
    font-style: italic;
    letter-spacing: -1px;
  }

  a {
    color: black;
  }

</style>
