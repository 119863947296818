<template>
    <textarea-autosize class="modal-textarea-catchy-title"
                       v-model="section.value"
                       :style="{color: card.textColor}"
    ></textarea-autosize>
</template>

<script>
import CardService from '../../../services/CardService'
import EventBus from '../../../event-bus'

export default {
  name: 'TitleComponent',
  props: ['idCardPage', 'idCard', 'title', 'card'],
  data () {
    return {
      lengthLimit: 40,
      placeholder: "TYPE YOUR MEANS' CATCHY TITLE HERE!",
      section: {
        idPageSection: '',
        value: '',
        position: 'TITLE',
        type: 'DEFAULT'
      }
    }
  },
  methods: {
  },
  watch: {
    sectionValue: function () {
      // this.length = this.section.value.length

      // Set value length include \n
      let matches = this.section.value.split(/[\n\r]/g)

      for (let key in matches) {
        let row = matches[key]
        this.addedLength = 10 - row.length
      }

      if (this.section.value.length > this.lengthLimit) {
        this.section.value = this.section.value.substr(0, this.lengthLimit - 1)
      }

      let card = this.card
      card['title'] = this.section.value

      // Always update the cards title alongside the cards title component.
      this.$emit('updateCardTitle', card)

      // Create section since the user now wants to use it.
      if (this.section.idPageSection <= 0 && this.card.writePermissions) {
        this.section.idPageSection = 'blocked'
        CardService.addSectionToPage(this.idCard, this.idCardPage, this.section).then((response) => {
          this.section = response.data
        })
      } else {
        // Check if section is not still being added in the first request.
        if (this.section.idPageSection !== 'blocked' && this.card.writePermissions) {
          CardService.updateSection(this.idCard, this.idCardPage, this.section.idPageSection, this.section)

          this.$emit('update:title', this.section.value)
          EventBus.$emit('update:title', this.section.value)
          EventBus.$emit('cards-refresh', true)

        }
      }
    }
  },
  mounted () {
    CardService.getPageAndSections(this.idCard, this.idCardPage).then((response) => {
      if (response.data.sections) {
        for (let section of response.data.sections) {
          if (section.position === this.section.position) {
            this.section = section
          }
        }
      }
    })

    EventBus.$on('modeChange', mode => {
      this.$set(this.card, 'editMode', mode)
      this.$forceUpdate()
    })
  },
  computed: {
    sectionValue () {
      // Less weird watcher, please see watchers above.
      return this.section.value
    },
    cardTitle: {

      get: function () {
        return this.title
      },
      set: function (value) {
        this.title = value
      }
    }
  }
}

</script>

<style scoped>

</style>
