<template>

  <div>
    <AlertComponent :message="successAlert.message" type="nobb-alert-primary"
                    v-if="successAlert.enabled"></AlertComponent>
    <div class="modal fade" id="signUpModal" tabindex="-1" aria-hidden="true"
         data-bs-backdrop="true" v-if="modalShow">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content center">
          <div class="float-left" style="z-index: 9999">
            <nav class="navbar navbar-expand-lg nobb-nav">
              <a class="navbar-brand" href="#/"><img src="../../assets/branding/Rübe.png"/></a>
              <a href="#/"><h3>NOBB.io</h3></a>
              <input id="search" type="text" class="form-control navbar-search"
                     placeholder="Search for ways and means of innovation" style="visibility: hidden">
              <a href="#" style="display: none">Cancel</a>

              <a href="#" style="margin: 0 16px;" data-bs-toggle="modal" data-bs-target="#signUpModal" id="closeModal">Cancel</a>
              <button class="btn" disabled v-if="!login">Sign Up →</button>
              <button class="btn" disabled v-if="login">Login→</button>
            </nav>
          </div>
          <div class="modal-body" style="padding: 100px 500px 500px;">
            <!-- Signup Section !-->
            <div class="col-md-6" v-show="!login">
              <AlertComponent :message="errorAlert.message" type="nobb-alert-warning"
                              v-if="errorAlert.enabled"></AlertComponent>
              <h4>Sign Up</h4>

              <hr style="color: black !important; height: 2px; opacity: 100%; margin-bottom: 50px">

              <div class="form-group">
                <label for="emai">E-Mail </label>
                <input class="form-control" type="text" placeholder="Enter your Email address*" id="email"
                       v-model="signUpUser.email" v-validate="'required|email'" name="email">
                <i style="color: red" v-show="errors.first('email')">{{ errors.first('email') }}</i>
              </div>

              <div class="form-group" style="padding-top: 15px;">
                <label for="penname">Pen Name</label>
                <input class="form-control" type="text" placeholder="Choose your pen name*" id="penname"
                       v-model="signUpUser.penName" v-validate="'required'" name="Pen Name" required>
                <i style="color: red" v-show="errors.first('Pen Name')">{{ errors.first('Pen Name') }} </i>
                <i style="color: rgba(0, 0, 0, 0.5);">This will be visible to other users on your published cards.</i>
              </div>

              <div class="form-group" style="padding-top: 5px;">
                <label for="colorPicker">Color</label>
                <input class="form-control" type="color" id="colorPicker" v-model="signUpUser.cardColor"
                       @blur="validateColorField=true" required>
                <i style="color: red" v-show="validateColorField && !signUpUser.cardColor">Please select a color!</i>
              </div>

              <div class="form-group" style="padding-top: 15px;">
                <label for="password">Password</label>
                <input class="form-control" type="password" id="password" placeholder="Choose your password*"
                       v-model="signUpUser.password">
                <i style="color: rgba(0, 0, 0, 0.5); ">Must consist of at least 5 characters</i>
              </div>

              <div class="form-group">
                <label for="validatePassword">Repeat password</label>
                <input class="form-control" type="password" id="validatePassword" placeholder="Repeat your password*"
                       v-model="signUpUser.passwordValidation" @blur="validatePassword=true">
              </div>

              <i style="color: red" v-show="validatePassword && signUpUser.password !== signUpUser.passwordValidation">Passwords
                do not match!</i>

              <alert-component v-if="errorAlert.enabled" type="nobb-alert-warning"
                               :message="errorAlert.message"></alert-component>

              <alert-component v-if="successAlert.enabled" type="nobb-alert-primary"
                               :message="successAlert.message"></alert-component>

              <button class="form-control nobb-form-button" style="margin-top: 15px;" v-on:click="signUp()"
                      :disabled="!signUpUser.email || !signUpUser.penName || !signUpUser.cardColor || !signUpUser.password || !signUpUser.passwordValidation && signUpUser.password === signUpUser.passwordValidation">
                Sign up now →
              </button>
              <i style="padding-left: 40%; color: rgba(0, 0, 0, 0.5);">Got an account? <a href="#" class="fw-bold"
                                                                                          v-on:click="login=true">Log
                In</a> </i>

            </div>

            <!-- Login section !-->
            <div class="col-md-6" v-if="login">
              <AlertComponent :message="errorAlert.message" type="nobb-alert-warning"
                              v-if="errorAlert.enabled"></AlertComponent>
              <h4>Log in {{ user }}</h4>
              <hr style="color: black !important; height: 2px; opacity: 100%; margin-bottom: 50px">
              <div class="form-group">
                <label for="email">E-Mail </label>
                <input class="form-control" type="text" placeholder="Enter your Email address" id="email"
                       v-model="loginUser.username">
              </div>

              <div class="form-group" style="padding-top: 15px;">
                <label for="password">Password</label>
                <input class="form-control" type="password" id="password" placeholder="Enter your password"
                       v-model="loginUser.password">
              </div>

              <DecisionAlertComponent type="nobb-alert-warning" :message="userFeedback.errorMessage"
                                      reject-message="Try again" ack-message="Reset password"
                                      v-if="userFeedback.errorMessage" v-on:close="userFeedback.errorMessage = false"
                                      @rejected="userFeedback.errorMessage = false"
                                      @acknowledged="this.requestPasswordReset"></DecisionAlertComponent>

              <br>
              <button class="form-control nobb-form-button" style="margin-top: 10px"
                      :disabled="!loginUser.username || !loginUser.password" v-on:click="logIn">Login →
              </button>
              <i style="padding-left: 40%; color: rgba(0, 0, 0, 0.5);">Not yet registered? <a href="#" class="fw-bold"
                                                                                              v-on:click="login=false">Sign
                Up</a> </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../event-bus'
import axios from 'axios'
import AlertComponent from '../alert/AlertComponent'
import DecisionAlertComponent from "../alert/DecisionAlertComponent";
import AuthService from "../../services/AuthService";

export default {
  name: 'OverlayRegisterComponent',
  components: {AlertComponent, DecisionAlertComponent},
  props: ['action'],
  data() {
    return {
      login: false,
      modalShow: true,
      signUpUser: {
        email: '',
        penName: '',
        cardColor: '',
        password: '',
        passwordValidation: ''
      },
      loginUser: {
        username: '',
        password: ''
      },
      validatePassword: false,
      validateColorField: false,
      errorAlert: {
        enabled: false,
        message: ''
      },
      successAlert: {
        enabled: false,
        message: ''
      },
      userFeedback: {
        errorMessage: '',
        successMessage: ''
      }
    }
  },
  mounted() {
    EventBus.$on('login', () => {
      this.login = true
    })

    EventBus.$on('signup', () => {
      this.login = false
    })
  },
  methods: {
    signUp() {
      let formData = new FormData()

      formData.append('username', this.signUpUser.email)
      formData.append('companyName', this.signUpUser.penName) // A pen name is actually a company, but we have decided to not show this type of functioniality yet.
      formData.append('companyColor', this.signUpUser.cardColor)
      formData.append('password', this.signUpUser.password)
      formData.append('validatePassword', this.signUpUser.passwordValidation)

      axios.post(process.env.VUE_APP_NOBB_API + 'user/register', formData, {},
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => {
        // this.successAlert.enabled = true;
        // this.successAlert.message = response.data['message'];
        // document.getElementById('closeModal').click();

        this.loginUser.username = this.signUpUser.email
        this.loginUser.password = this.signUpUser.password

        // document.getElementById('closeModal').click();
        // this.resetState()
        // this.logIn();
        document.getElementById('closeModal').click();
        this.logIn(true)

      }).catch((error) => {
        console.log(error.response['data'].message)
        this.errorAlert.enabled = true;
        this.errorAlert.message = error.response['data'].message
      })
    },
    resetState() {
      this.signUpUser = false;
    },
    logIn(closeModal = false) {
      axios.post(process.env.VUE_APP_NOBB_API + 'auth', {
        username: this.loginUser.username,
        password: this.loginUser.password
      }).then((response) => {
        this.$store.commit('loginUser', response.data)
        this.successAlert.message = "You have been logged in!"
        this.successAlert.enabled = true

        if (closeModal) {
          document.getElementById('closeModal').click();
          this.loginUser.username = ''
          this.loginUser.password = ''
        }
      }).catch((error) => {
        this.userFeedback.errorMessage = error.response['data'].message
      })
    },
    requestPasswordReset: function () {
      AuthService.requestPasswordReset(this.loginUser.username).then((response) => {
        this.userFeedback.errorMessage = false
        this.userFeedback.successMessage = response.data.message
        this.passwordReset.email = ''
      }).catch((error) => {
        this.userFeedback.errorMessage = error.response.data.message
      })
    },
  }
}
</script>

<style scoped>
.form-control {
  padding: 10px;
}

.nobb-form-button {
  background: black;
  color: white;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  line-height: 20px;
  height: 40px;
  margin-bottom: 10px;
}

</style>
