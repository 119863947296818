// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VeeValidate from 'vee-validate'
// import { ValidationProvider } from 'vee-validate';
import VueTextareaAutosize from 'vue-textarea-autosize'
import Autocomplete from 'vuejs-auto-complete'
import VueProgressBar from 'vue-progressbar'
import VueAutoSize from 'vue-autosize'
import BootstrapVue from 'bootstrap-vue'
import {store} from './store'
import VueScrollTo from 'vue-scrollto'
import Vue2TouchEvents from 'vue2-touch-events'
import VueInputAutowidth from 'vue-input-autowidth'

// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/style/main.scss'
import './assets/fonts/NOBB_FONT/MyFontsWebfontsKit_unhinted.css'
import './assets/fonts/NOBB_FONT/webfonts/34D30C_0_0.woff2'

// Sentry.init({
//   dsn: 'https://822db07943dc4cca96fca8df9de1a839@sentry.dev.ti-more.net/3',
//   integrations: [new Integrations.Vue({Vue, attachProps: true})]
// })

Vue.config.runetimeCompiler = true

Vue.use(BootstrapVue)
// Vue.config.productionTip = false
Vue.use(VeeValidate, {fieldsBagName: 'validation-fields', classes: true, events: 'blur'})
// Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueTextareaAutosize)
Vue.component('autocomplete', Autocomplete)
Vue.use(VueAutoSize)
Vue.use(VueScrollTo)
Vue.use(Vue2TouchEvents)
Vue.use(VueInputAutowidth)

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  thickness: '5px',
  height: '10px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  }
})

/* eslint-disable no-new */
export default new Vue({
  el: '#app',
  router,
  store,
  beforeCreate () {
    // Refreshing vuex state from localStorage if user refreshes app.
    this.$store.commit('initialiseStore')
  },
  components: { App },
  template: '<App/>'
})
