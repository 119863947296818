<template>
    <div class="app">
        <div class="container">
            <div class="row">
                <div class="col-md-12 large-top-spacer">
                    <h1>Hi Joachim, </h1>
                    <h3 class=" angled-right">
                        Welcome to NOBB.IO.<br>
                        THANK YOU VERY MUCH FOR JOINING IN. <br>
                        WE ARE LOOKING FORWARD A LOT TO LEARN ABOUT YOUR <br> WAYS AND MEANS FOR INNOVATION. IN ORDER TO <BR/>
                        COMPLETE YOUR SIGNUP, WE HAVE TO MAKE SURE <br> WE GOT YOUR EMAIL ADDRESS RIGHT. <BR/>
                        THEREFOR PLEASE CONFIRM IT.
                    </h3>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-4 small-top-spacer">
                    <button class="btn-primary email-button">CONFIRM NOW</button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 small-top-spacer">
                    <h3 class="angled-right text-uppercase">
                        So that's it - happy sharing! <br>
                        <br>
                        Best, <br>
                        The NOBBS
                    </h3>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 small-top-spacer">
                    <h3 class="angled-right text-uppercase">
                        Want to create your next card? <i class="underline">this way!</i>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WelcomeEmailComponent'
}
</script>

<style scoped>
    .angled-right {
        font-style: italic;
    }

    .email-button{
        height: 50px;
        width: 200px;
        font-size: 20pt;
    }

    .small-top-spacer {
        margin-top: 30px;
    }

    .large-top-spacer {
        margin-top: 180px;
    }

</style>
