<template>
  <div class="nobb-alert-2" v-bind:class="animation">
    <div class="clipped" v-bind:class="[type]" v-touch:swipe.left="swiped" v-show="!hide">
      <p><a :href="link" class="underline" target="_blank" v-on:click="close">SHARE CARD NOW VIA MAIL!</a> </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareAlertComponent',
  props: ['type', 'message', 'link'],
  data () {
    return {
      alert: true,
      hide: false,
      animation: ''
    }
  },
  methods: {
    swiped: function () {
      this.hide = 'close-alert'
    },
    close: function () {
      this.animation = 'close-alert'

      setTimeout(() => {
        this.hide = 'close-alert'
        this.$emit('close')
      }, 1000)
      // this.hide = 'close-alert'
    }
  }
}
</script>
