
<template>
    <div class="nobb-alert-2" v-bind:class="animation">
      <div class="clipped" v-bind:class="[type]" v-touch:swipe.left="swiped" v-show="!hide">
        <p v-if="!retry">{{message}} <a href="#" class="underline" v-on:click.prevent="close($event)" v-on:click="close">CLOSE</a> </p>
      </div>
    </div>
</template>

<script>
import EventBus from '../../event-bus'

export default {
  name: 'AlertComponent',
  props: ['type', 'message', 'retry', 'id'],
  data () {
    return {
      alert: true,
      hide: false,
      animation: ''
    }
  },
  methods: {
    swiped: function () {
      this.hide = 'close-alert'
    },
    close: function () {
      this.animation = 'close-alert'

      setTimeout(() => {
        this.hide = 'close-alert'
        this.$emit('close')
      }, 1000)
      EventBus.$emit(this.id, 'closed')
    }
  }
}
</script>
