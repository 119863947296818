<template>
    <textarea-autosize class="modal-textarea-subtitle" v-b-tooltip :title="this.section.value.length + ' / ' + this.lengthLimit"
                       v-model="section.value" :style="{color: card.textColor}"
    ></textarea-autosize>
</template>

<script>
import CardService from '../../../services/CardService'

export default {
  name: 'SubtitleComponent',
  props: ['idCardPage', 'idCard', 'card'],
  data () {
    return {
      lengthLimit: 1486,
      section: {
        idPageSection: '',
        value: '',
        position: 'SUBTITLE',
        type: 'DEFAULT'
      }
    }
  },
  methods: {
    getValue: function () {
      if (this.card.writePermissions) {
        return this.section.value.length + '/' + this.lengthLimit
      }
    }
  },
  watch: {
    sectionValue: function () {
      if (this.section.value.length > this.lengthLimit) {
        this.section.value = this.section.value.substr(0, this.lengthLimit - 1)
      }

      // Create section since the user now wants to use it.
      if (this.section.idPageSection <= 0 && this.card.writePermissions) {
        this.section.idPageSection = 'blocked'
        CardService.addSectionToPage(this.idCard, this.idCardPage, this.section).then((response) => {
          this.section = response.data
        })
      } else {
        // Check if section is not still being added in the first request.
        if (this.section.idPageSection !== 'blocked' && this.card.writePermissions) {
          CardService.updateSection(this.idCard, this.idCardPage, this.section.idPageSection, this.section)
        }
      }
    }
  },
  mounted () {
    // Check if section already exists.
    CardService.getPageAndSections(this.idCard, this.idCardPage).then((response) => {
      if (response.data.sections) {
        for (let section of response.data.sections) {
          if (section.position === this.section.position) {
            this.section = section
          }
        }
      }
    })
  },
  computed: {
    sectionValue () {
      // Less weird watcher, please see watchers above.
      return this.section.value
    }
  }
}
</script>

<style scoped>

</style>
