<template>
    <div class="container-fluid large-top-spacer">
        <hr class="hr-black">
        <div class="alert alert-danger" role="alert" v-show="userFeedback.errorMessage">
            {{userFeedback.errorMessage}}
        </div>

        <div class="alert alert-success" role="alert" v-show="userFeedback.successMessage">
            {{userFeedback.successMessage}}
        </div>

        <div v-if="user.company">
            <h2>{{user.firstName | capitalize}} YOU'RE ALMOST THERE</h2> <br>
            <h3>TO LOG IN AND CREATE AWESOME NEW CARDS, YOU SIMPLY HAVE TO CHOOSE <br>
                A <input
                        type="password" class="login-control" placeholder="NEW PASSWORD"
                        v-autowidth="{maxWidth: '960px', minWidth: '50px', comfortZone: 3}"
                        v-model="passwordReset.password"> AND REPEAT THIS <input
                        type="password" class="login-control" placeholder="PASSWORD"
                        v-autowidth="{maxWidth: '960px', minWidth: '50px', comfortZone: 3}"
                        v-model="passwordReset.validatePassword"> <br>
                <br>
                <button class="btn-primary" v-on:click="resetPassword" :disabled="!passwordReset.validatePassword">Reset password</button> <br>
                <br>
                THAT'S IT - HAPPY SHARING!
            </h3>

        </div>
    </div>
</template>

<script>
import AuthService from '../../services/AuthService'

export default {
  name: 'ResetPasswordComponent',
  data () {
    return {
      userFeedback: {
        errorMessage: '',
        successMessage: ''
      },
      passwordReset: {
        password: '',
        validatePassword: ''
      },
      user: {}
    }
  },
  props: ['hash'],
  mounted () {
    this.hash = this.$route.params['hash']

    AuthService.getPasswordHash(this.hash).then((response) => {
      this.user = response.data.user
      this.hashValidUntil = response.data.hashValidUntil
    }).catch((error) => {
      this.userFeedback.errorMessage = error.response.data.message
    })
  },
  methods: {
    resetPassword: function () {
      if (this.passwordReset.password === this.passwordReset.validatePassword) {
        AuthService.resetPassword(this.hash, this.passwordReset.password).then((response) => {
          this.userFeedback.errorMessage = false
          this.userFeedback.successMessage = response.data.message

          setTimeout(() => {
            this.$router.push('/')
          }, 1000)
        }).catch((error) => {
          this.userFeedback.errorMessage = error.response.data.message
        })
      } else {
        this.userFeedback.errorMessage = 'Passwords are not equal. '
      }
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    }
  }
}
</script>

<style scoped>

</style>
