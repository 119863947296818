<template>
  <div class="app">
    <overlay-register-component></overlay-register-component>
    <overlay-profile-component></overlay-profile-component>
    <AlertComponent :type="activateAlert.type" :message="activateAlert.message" v-if="activateAlert.show"/>

    <div class="container-fluid">

      <div class="large-top-spacer"></div>

      <div class="col-md-10">
        <div class="h-100 p-5 rounded-3 nobb-rounded-welcome-container"
             v-show="welcomeContainer && !userSession.loggedIn">
          <a href="#" style="color: black" v-on:click="welcomeContainer=false"> <i class="float-right">Close ˣ</i></a>

          <div class="row">
            <div class="col-md-7">
              <h3 class="text-uppercase">HI, WELCOME TO</h3>
              <h1>NOBB.IO</h1>
              <h3>your platform to share ways and means for innovation – find and feature methods, techniques and
                principles on how to invent, present & sell ideas and turning them into reality.</h3>
            </div>
            <div class="col-md-3">
              <div class="welcomeAnimationContainer"><h1 v-html="welcomeText"></h1></div>
            </div>
            <br>
          </div>


          <div class="col-md-7">
            <div class="row">
              <button class="btn btn-large" data-bs-toggle="modal" data-bs-target="#signUpModal"
                      v-on:click="emitSignUpEvent">Sign up now →
              </button>
            </div>
            <div class="row">
              <i>Got an account? <a href="#" class="fw-bold" v-on:click="emitLoginEvent" data-bs-toggle="modal"
                                    data-bs-target="#signUpModal">Log In</a> </i>
            </div>
          </div>
        </div>
      </div>

      <div class="small-top-spacer"></div>
    </div>
    <card-viewer-component user-only="true" v-if="userSession.loggedIn && showUserCards"></card-viewer-component>
    <card-viewer-component></card-viewer-component>
  </div>
</template>

<script>
import CardViewerComponent from './card/CardViewerComponent'
import OverlayRegisterComponent from './user/OverlayRegisterComponent'
import EventBus from '../event-bus'
import OverlayProfileComponent from './user/OverlayProfileComponent'
import UserService from "../services/UserService";
import AlertComponent from "./alert/AlertComponent.vue";

document.documentElement.setAttribute('data-browser', navigator.userAgent)

export default {
  name: 'HomeComponent',
  props: ['dataProtection', 'collapseOpen', 'registerFromHash'],
  components: {AlertComponent, CardViewerComponent, OverlayRegisterComponent, OverlayProfileComponent},
  data() {
    return {
      userDetailsToggle: false,
      email: '',
      activateAlert: {
        type: 'nobb-alert-primary',
        message: 'This is a success message',
        show: false
      },
      welcomeContainer: true,
      overlay: false,
      login: false,
      showUserCards: true,
      welcomeText: 'From Idea to <br> Reality',
      welcomeTexts: [
        "From Idea to <br> Reality",
        "How?",
        "NOBB.io",
        "Happy sharing."
      ],
      welcomeTextsCounter: 0
    }
  },
  computed: {
    userSession() {
      return this.$store.state.user
    },
  },
  mounted() {
    EventBus.$on('cardsUpdated', () => {
      // Stop rendering of showCards
      this.showUserCards = false

      // On the next tick re-render the cardViewer
      this.$nextTick().then(() => {
        this.showUserCards = true
      })
    })

    // Animate text
    setInterval(function () {
      this.welcomeText = "hello"
    }, 1000);

    this.todo()

    if (this.$route.params.activateHash) {
      UserService.activateUser(this.$route.params.activateHash).then((response) => {
        this.activateAlert.show = true
        this.activateAlert.message = response.data.message
        this.activateAlert.type = 'nobb-alert-primary'
      }).catch((error) => {
        this.activateAlert.show = true
        this.activateAlert.type = 'nobb-alert-warning'
        this.activateAlert.message = error.response.data.message
      })
    }

    function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }

    console.log(this.$s)
    // Check user session
    if (this.$store.state.user.loggedIn) {
      // get current epoch time in javascript
      var currentTime = Math.round(new Date().getTime() / 1000)
      if (currentTime > parseJwt(this.$store.state.user.jwt.token)['exp']) {
        this.$store.commit('logoutUser')
      }
    }
  },
  methods: {
    updateSource(email) {
      this.source = process.env.VUE_APP_NOBB_API + 'user/register?&email=' + email + '&companyName='
    },
    emitLoginEvent() {
      EventBus.$emit('login')
    },
    emitSignUpEvent() {
      EventBus.$emit('signup')
    },
    todo: function () {

      this.intervalid1 = setInterval(function () {
        this.welcomeTextsCounter = this.welcomeTextsCounter + 1

        if (this.welcomeTextsCounter >= this.welcomeTexts.length) {
          this.welcomeTextsCounter = 0
        }

        this.welcomeText = this.welcomeTexts[this.welcomeTextsCounter]
      }.bind(this), 3000);
    }
  }
}

</script>
