import Api from './Api'

export default {
  getUserProfile () {
    return Api().get('/user/profile')
  },
  updateUserProfile (data) {
    return Api().put('/user/profile', data)
  },
  updatePasswordWithOldPassword (idUser, originalPassword, newPassword) {
    return Api().post('/user/password', {
      idUser: idUser,
      originalPassword: originalPassword,
      newPassword: newPassword
    })
  },
  downloadUserData () {
    return Api().get('/user/gdpr', {responseType: 'blob'})
  },
  deletionRequest (newAdmin) {
    if (newAdmin) {
      return Api().delete('/user/gdpr?newAdmin=' + newAdmin)
    } else {
      return Api().delete('/user/gdpr')
    }
  },
  activateUser (hash) {
    return Api().post('/user/activate/' + hash)
  },
  getDetailsFromInviteHash (hash) {
    return Api().get('user/invite/' + hash)
  }
}
