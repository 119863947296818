import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const user = {
  username: '',
  password: '',
  name: '',
  surname: '',
  phoneNumber: '',
  favoriteColour: '',
  websiteLink: '',
  company: {
    name: '',
    logo: '',
    address: ''
  },
  loggedIn: false
}

export const store = new Vuex.Store({
  state: {
    user: user
  },
  mutations: {
    logoutUser(state) {
      state.user = user
      state.user.loggedIn = false
    },
    updateLogo(state, logo) {
      state.user.company.systemFileName = logo
    },
    updateCompanyColor(state, color) {
      state.user.company.companyColor = color
    },
    loginUser(state, loggedInUser) {
      state.user = loggedInUser
      state.user.loggedIn = true
    },
    initialiseStore(state) {
      if (localStorage.getItem('nobb-storage')) {
        this.replaceState(
            Object.assign(state, JSON.parse(localStorage.getItem('nobb-storage')))
        )
      }
    }
  },
  getters: {
    getJwtToken: state => {
      if (typeof (state.user.jwt) !== 'undefined') {
        return state.user.jwt.token
      } else {
        return false
      }
    }
  }
})

store.subscribe((mutation, state) => {
  localStorage.setItem('nobb-storage', JSON.stringify(state))
})
