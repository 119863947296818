import Api from './Api'

export default {
  requestPasswordReset (email) {
    return Api().put('/auth/password/', {'email': email})
  },
  getPasswordHash (hash) {
    return Api().get('/auth/password/' + hash)
  },
  resetPassword (hash, password) {
    return Api().post('/auth/password', {'passwordHash': hash, 'password': password})
  }
}
