import Api from './Api'

export default {
  updateCompanyProfile (data) {
    return Api().put('/company/info', data)
  },
  sendCompanyInvite (idCompany, email) {
    return Api().post('/company/' + idCompany + '/users', {email: email})
  },
  updateCompanyLogo (idCompany, formData) {
    return Api().post('/company/' + idCompany + '/logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  grantAdminRights (idCompany, idUser) {
    return Api().put('/company/' + idCompany + '/users/' + idUser + '/admin')
  },
  revokeAdminRights (idCompany, idUser) {
    return Api().delete('/company/' + idCompany + '/users/' + idUser + '/admin')
  },
  getUsers (idCompany) {
    return Api().get('/company/' + idCompany + '/users')
  }
}
